@import "_boilerplate";

html, body{
  overflow: hidden;
  @include respond-to(handhelds){ overflow: initial }
}

.page--container {
  min-height: 100vh;
  width: 100vw;
  max-width: 1440px;
  margin: 0 auto;
  color: var(--text);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  @include respond-to(handhelds) { 
    flex-direction: column;
    max-width: 100vw;
    overflow: hidden;
  }
}

.left--container {
  background-color: transparent;
  width: 100%;
  height: 100vh;
  flex: 1 1 50%;

  @include respond-to(handhelds){
    margin-top: 10%;
    display: none;
  }

  figure {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0;

    svg {
      filter: drop-shadow(0px 19px 46px rgba(0, 0, 0, 0.2));
      width: 344px;
      height: auto;
      position: absolute;
      z-index: 4;

      @include respond-to(handhelds){
        position: relative;
      }
    }

    @include respond-to(handhelds){
      height: auto;
    }
  }
}

.right--container {
  background-color: var(--primary-color);
  height: 100vh;
  z-index: 13;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  padding: 0 100px 0 0;
  justify-content: center;
  align-items: center;

  @include respond-to(handhelds){
    flex-direction: column;
    padding: 0 20px;
    order: -1;
    text-align: center;
    z-index: 1;
    flex: 1 0 auto;
    max-height: 65vh;
    font-size: 12px;
  }

  @media (min-width: 768px) and (max-width: 1025px) { padding: 0 30px 0 0 }
  @media (min-width: 1026px) and (max-width: 1112px) { padding: 0 60px 0 0 }

  .buttons {
    height: 5em;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    @include respond-to(handhelds){
      justify-content: center;
      height: unset;
    }
  }

  .beta {
    height: 100%;
    max-height: 5rem;
    background: #000;
    color: #fff;
    border-radius: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 1.3rem;
    font-weight: 700;
    border: 1.5px solid #a6a6a6
  }

  .content--wrap {
    height: auto;
    transition: .15s;

    @media Screen and (max-height: 970px){ height: 650px }

    @include respond-to(handhelds){
      display: flex;
      flex-direction: column;
    }

    @include respond-to(medium-screens){
      padding: 30px;
      background-color: var(--bg);
      border-radius: 14px;
      box-shadow: 0px 19px 46px rgba(0, 0, 0, 0.2);
    }

    @media (min-width: 768px) and (max-width: 1112px) and (orientation: landscape) {
      padding: 30px;
      background-color: var(--bg);
      border-radius: 14px;
      box-shadow: 0px 19px 46px rgba(0, 0, 0, 0.2);
    }

    .logo {
      align-self: flex-start;
      background-image: url("../img/Logo.webp");
      min-height: 64px;
      min-width: 64px;
      max-width: 64px;
      background-size: 64px;
      border-radius: 14px;
      margin: 0 0 10px 0;

      @include respond-to(handhelds){
        align-self: center;
        margin: 10px 40px;
      }
    }

    .heading {
      font-size: 5rem;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 10px;

      @include respond-to(handhelds){
        font-size: 3.2rem;
      }

      @include respond-to(medium-screens){
        font-size: 4rem;
      }

      @include respond-to(xl-screens){
        font-size: 6rem;
      }
    }

    .lead {
      font-size: 1.7rem;
      line-height: 2rem;

      @include respond-to(handhelds){
        font-size: 1.5em;
        line-height: unset;
      }

      @include respond-to(medium-screens){
        font-size: 1.6em;
      }
    }

    .AppStore{
      @include respond-to(handhelds) { align-self: center }
    }

    .AppStore--badge{
      background-image: url("../img/AppStoreBadge.svg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 15rem;
      height: 5rem;
      margin: 0;
      align-self: start;

      @include respond-to(handhelds){
        background-position: center;
      }
    }

    .credits, .creditsMobile, .terms, .termsMobile {
      a {
        text-decoration: none;
        color: var(--links);
        cursor: pointer;

        &:hover{
          text-decoration: underline;
        }
      }
    }

    .credits {
      color: var(--credits);
      background-color: var(--bg);
      border-radius: 14px;
      padding: 15px 30px;
      position: absolute;
      left: 20px;
      bottom: 5px;
      z-index: 20;
      display: initial;

      @include respond-to(handhelds){
        display: none;
        padding: 5px;
      }
    }

    .terms {
      z-index: 20;
      display: initial;

      @include respond-to(handhelds){
        display: none;
        padding: 5px;
      }
    }
  }
}

.termsMobile {
  color: var(--text);
}

.footer-mobile {
  color: var(--credits);
  background-color: var(--bg);
  z-index: 20;
  font-size: .8em;
  position: fixed;
  text-align: center;
  border-radius: 36px 36px 0 0;
  margin: 0;
  left: 0;
  padding: 12px;
  bottom: 0;
  width: 100%;
  display: none;
  text-decoration: none;

  @include respond-to(handhelds) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: var(--links);
    cursor: pointer;

    &:hover {
      text-decoration: underline
    }
  }
}

.wave {
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 350px;
  bottom: -4px;

  @include respond-to(handhelds){
    position: fixed;
    transform: scaleY(2.5);
  }

  img {
    width: 100%;
  }
}

.wave--green {
  max-height: 320px;

  @include respond-to(handhelds){ 
    max-height: 350px;
    z-index: 5;
  }
}

.wave--red{
    @include respond-to(handhelds){ 
    max-height: 350px;
    z-index: 5;
  }
}

.wave--blue {
  z-index: 5;
  max-height: 280px;

  @include respond-to(handhelds){ 
    max-height: 350px;
  }
}
