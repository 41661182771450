:root {
  --primary-color: hsl(1, 100, 50);
  --text: #000;
  --credits: #7D7D7D;
  --links: #000;
  --bg: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: hsl(1, 100, 50);
    --text: #fff;
    --credits: #fff;
    --links: hsl(1, 100, 50);
    --bg: #181818;
  }
}

$sm: 576px;
$lg: 1024px;
$xl: 1340px;

@mixin respond-to($media) {
  @if $media == handhelds {
    @media only screen and (max-width: $sm) {
      @content;
    }
  } @else if $media == medium-screens {
    @media only screen and (min-width: $sm + 1) and (max-width: $lg - 1) {
      @content;
    }
  } @else if $media == wide-screens {
    @media only screen and (min-width: $lg) and (max-width: $xl) {
      @content;
    }
  } @else if $media == xl-screens {
    @media only screen and (min-width: $xl) {
      @content;
    }
  }
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  background-color: var(--bg);
  color: var(--text);
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;

  @include respond-to(handhelds) {
    overflow: initial;
  }

  @media (min-height: 630px) and (max-height: 664px) {
    font-size: 12px;
  }

  @media (min-height: 665px) and (max-height: 730px) {
    font-size: 13px;
  }
}

h1, h2, h3, h4, h5, p {
  text-wrap: balance;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}